import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { AuthContextProvider } from './AuthContextProvider';
import ProtectedRoute from './ProtectedRoute';
import LoginScreen from './screens/LoginScreen';
import HomePage from './screens/HomePage';
import Privacy from "./screens/Privacy";
import TAT from './screens/TAT';
import RAR from './screens/RAR';
import TermsOfService from './screens/tc';
import PrintScreen from './screens/PrintScreen';
import PdfDetailsScreen from './screens/PdfDetailsScreen';


function App() {
  return (
    <div className="App">
      <AuthContextProvider>
          <NavBar />
          <ToastContainer />
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                 
                  <HomePage />
                </ProtectedRoute>
              }
            /> 
           

          
                    <Route path="/details/:fileId" element={
                      
                      <ProtectedRoute>
                        <PdfDetailsScreen/>
                        </ProtectedRoute>} />
                   
             <Route
              path="/print"
              element={
                <ProtectedRoute>
                 
                  <PrintScreen />
                </ProtectedRoute>
              }
            />

<Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/tat" element={<TAT/>} />
            <Route exact path="/tnc" element={<TermsOfService />} />

            <Route exact path="/rar" element={<RAR />} />
            

          </Routes>
          <Footer />
         
      </AuthContextProvider>
    </div>
  );
}

export default App;
